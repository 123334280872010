import { createActionsHook } from 'react-sweet-state';

import { createDataHook, createRestStore, Mapper } from '@intelliarts/ia-react-template';

import { Category } from '../models/Category';
import { CategoryView } from '../models/CategoryView';

export const CATEGORIES_PATH = '/categories';

const BASE_URL = '/api/';

const categoryMapper: Mapper<CategoryView, Category> = {
  toModel: entity => {
    const {
      id,
      name,
      position,
      icon,
      isArchived
    } = entity;

    return ({
      id,
      name,
      position,
      icon,
      isArchived
    });
  },
  toEntity: model => {
    const {
      id,
      name,
      position,
      icon,
      isArchived
    } = model;

    const isArchivedDefault = isArchived ? isArchived : false;

    return {
      id,
      name,
      position,
      icon,
      isArchived: isArchivedDefault
    };
  }
};

export const CategoryStore = createRestStore<CategoryView, Category>({
  containerDisplayName: 'category-container',
  actionConfig: {
    resourcePath: CATEGORIES_PATH,
    axiosConfig: { baseURL: BASE_URL },
  },
  mapper: categoryMapper,
});

export const useCategoryActions = createActionsHook(CategoryStore);

export const useCategoriesData = createDataHook(CategoryStore);
