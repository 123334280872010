import { createActionsHook, StoreActionApi } from 'react-sweet-state';

import {
  createDataHook,
  createLoadingHook,
  createRestStore,
  getAxiosInstance,
  RestStoreState,
} from '@intelliarts/ia-react-template';

import { Order } from '../models/Order';
import { StringDateRangeProps } from '../../common/models/DateRangeProps';

export const ORDER_PATH = '/period-orders/';

const BASE_URL = '/api';

const actionConfig = {
  resourcePath: ORDER_PATH,
  axiosConfig: { baseURL: BASE_URL },
};

const actions = {
  createOrder: (firstDate: string, lastDate: string) =>
    async ({ setState, getState }: StoreActionApi<RestStoreState<Order>>) => {
      const axiosInstance = getAxiosInstance({ baseURL: BASE_URL });
      try {
        const order = {
          firstDate: firstDate,
          lastDate: lastDate,
          ordered: false,
        };
        const response = await axiosInstance.post<unknown, Order>(`/period-orders`, order, {});
        setState({ ...getState(), data: [ ...getState().data, response ] });
        return response;
      } finally {
      }
    },
  updateOrderItem: (order: Order) =>
    async ({ setState, getState }: StoreActionApi<RestStoreState<Order>>) => {
      const axiosInstance = getAxiosInstance({ baseURL: BASE_URL });
      const previousState = getState();
      order.ordered = false;
      setState({ ...previousState, data: [ ...previousState.data.filter(or => or.id !== order.id), order ] });
      axiosInstance.put<unknown, Order>(`/period-orders/${order.id}`, order, {})
        .catch(() => {
          setState({ ...previousState });
        });
    },
  performOrder: (order: Order) =>
    async ({ setState, getState }: StoreActionApi<RestStoreState<Order>>) => {
      const axiosInstance = getAxiosInstance({ baseURL: BASE_URL });
      const response = await axiosInstance.put<unknown, Order>(`/period-orders/${order.id}`, order, {});
      setState({ ...getState(), data: [ ...getState().data.filter(or => or.id !== order.id), response ] });
    },
};

export const OrderStore = createRestStore<Order, Order, StringDateRangeProps>({
  actions,
  containerDisplayName: 'order-container',
  actionConfig,
  usePropsAsActionParams: true,
});

export const useOrderActions = createActionsHook(OrderStore);

export const useOrderIsLoading = createLoadingHook(OrderStore);

export const useOrder = createDataHook(OrderStore);
