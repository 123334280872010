import { createDataHook, createLoadingHook, createRestStore } from '@intelliarts/ia-react-template';

import { DateToUserOrderMap } from '../models/DateToUserOrderMap';
import { StringDateRangeProps } from '../../common/models/DateRangeProps';

export const REPORTS_PATH = '/report/menu';

const BASE_URL = '/api/';

export const ReportsStore = createRestStore<DateToUserOrderMap, DateToUserOrderMap, StringDateRangeProps>({
  containerDisplayName: 'reports-container',
  actionConfig: {
    resourcePath: REPORTS_PATH,
    axiosConfig: { baseURL: BASE_URL },
  },
  usePropsAsActionParams: true,
});

export const useReports = createDataHook(ReportsStore);

export const useReportsAreLoading = createLoadingHook(ReportsStore);
