import { createDataHook, createRestStore } from '@intelliarts/ia-react-template';
import { StringDateRangeProps } from '../../common/models/DateRangeProps';
import { DateToVendorReportMap } from '../models/DateToVendorReportMap';

export const VENDOR_REPORT_PATH = '/report/vendor';

const BASE_URL = '/api/';

export const VendorReportStore = createRestStore<DateToVendorReportMap, DateToVendorReportMap, StringDateRangeProps>({
  containerDisplayName: 'vendor-report-container',
  actionConfig: {
    resourcePath: VENDOR_REPORT_PATH,
    axiosConfig: { baseURL: BASE_URL },
  },
  usePropsAsActionParams: true,
});

export const useVendorReport = createDataHook(VendorReportStore);

