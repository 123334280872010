import { useTranslation } from 'react-i18next';
import { DateRangeProps, StringDateRangeProps } from '../components/common/models/DateRangeProps';
import { formatToDtoDateString } from '@intelliarts/ia-react-template';

const LOCALE_PATH = 'src.utils';

export function getWeekTabsDateRangeString(isMobileView: boolean) {
  const { i18n } = useTranslation();

  return (date: Date) => {
    const weekBounds = getWeekBounds(date);

    const monthFormat = isMobileView ? 'short' : 'long';

    const localizedDateFrom = getLocalizedDate(weekBounds.from, i18n.language, {
      month: monthFormat,
      year: 'numeric',
      day: 'numeric'
    });
    const localizedDateTo = getLocalizedDate(weekBounds.to, i18n.language, {
      month: monthFormat,
      year: 'numeric',
      day: 'numeric'
    });

    return isMobileView
      ? getMobileDate(localizedDateFrom, localizedDateTo, i18n.language)
      : getDesktopDate(localizedDateFrom, localizedDateTo, i18n.language);
  };
}

function getMobileDate(localizedDateFrom: string, localizedDateTo: string, currentLanguage: string) {
  const [ day, month, year, _ ] = localizedDateFrom.split(' ');
  const [ dayTo, monthTo, yearTo, _To ] = localizedDateTo.split(' ');

  const charToRemove = currentLanguage === 'uk' ? '.' : ',';
  localizedDateFrom = `${day} ${month.replaceAll(charToRemove, '')}`;
  localizedDateTo = `${dayTo} ${monthTo.replaceAll(charToRemove, '')}`;

  return localizedDateFrom + ' – ' + localizedDateTo;
}

function getDesktopDate(localizedDateFrom: string, localizedDateTo: string, currentLanguage: string) {
  const [ day, month, year, _ ] = localizedDateFrom.split(' ');
  const [ dayTo, monthTo, yearTo, _To ] = localizedDateTo.split(' ');

  if (currentLanguage === 'uk') {
    localizedDateFrom = `${day} ${month} ${year}`;
    localizedDateTo = `${dayTo} ${monthTo} ${yearTo}`;
  }

  return localizedDateFrom + ' – ' + localizedDateTo;
}


function getDateLocales(currentLanguage: string): 'uk-UA' | 'en-US' {
  switch (currentLanguage) {
    case 'uk':
      return 'uk-UA';
    case 'en':
      return 'en-US';
    default:
      return 'uk-UA';
  }
}

export function getLocalizedDate(dateInput: Date | string,
                                 currentLanguage: string,
                                 dateLocalizeOptions?: Intl.DateTimeFormatOptions): string {
  const dateLocale: 'uk-UA' | 'en-US' = getDateLocales(currentLanguage);
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  return date
    .toLocaleDateString(dateLocale, dateLocalizeOptions)
    .toUpperCase();
}

export function formatDateForReportPrint(date: Date) {
  const { i18n, t } = useTranslation();
  const dayStr: Object = t(`${LOCALE_PATH}.weekDaysFull.${date.getDay()}`);

  let localizedDate = getLocalizedDate(date, i18n.language, { month: 'short', day: 'numeric' });
  const [ day, month, _ ] = localizedDate.split(' ');

  localizedDate = `${dayStr}, ${day} ${month}`;
  return localizedDate;
}

export function formatDateForOrderSidebar(dateStr: string) {
  const { i18n, t } = useTranslation();
  const date = new Date(dateStr);
  const dayNameFull: Object = t(`${LOCALE_PATH}.weekDaysFull.${date.getDay()}`);

  const [ _, day, month ] = getLocalizedDate(date, i18n.language, {
    month: 'long',
    day: '2-digit',
    weekday: 'long',
  }).split(' ');

  return `${dayNameFull}, ${day} ${month.toLowerCase()}`;
}

export function daysInYear(date: Date) {
  return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

function dateFromDay(year: number, day: number) {
  const date = new Date(Date.UTC(year, 0, 1));
  date.setUTCDate(day);
  return date;
}

export function getWeekBounds(date: Date): DateRangeProps {
  const currentDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  const currentYear = currentDate.getUTCFullYear();
  const firstWeekDayNumInYear = daysInYear(currentDate) - currentDate.getUTCDay() + 1;
  const lastWeekDayNumInYear = firstWeekDayNumInYear + 4;

  return {
    from: dateFromDay(currentYear, firstWeekDayNumInYear),
    to: dateFromDay(currentYear, lastWeekDayNumInYear),
  };
}

export function getDateRangeDates(firstDate: Date, lastDate: Date) {
  let days = [];
  const firstYear = firstDate.getFullYear();
  const firstDayNumInYear = daysInYear(firstDate);
  const lastDayNumInYear = daysInYear(lastDate);

  if (firstDate.getFullYear() === lastDate.getFullYear()) {
    const daysNum = lastDayNumInYear - firstDayNumInYear + 1;
    for (let i = 0; i < daysNum; i++) {
      days.push(formatToDtoDateString(dateFromDay(firstYear, firstDayNumInYear + i)));
    }
  } else {
    const isLeap = firstYear % 4 === 0;
    const yearDays = isLeap ? 366 : 365;
    const daysLeftInYear = yearDays - firstDayNumInYear;
    const daysNum = daysLeftInYear + lastDayNumInYear + 1;
    for (let i = 0; i < daysNum; i++) {
      let year = firstDayNumInYear + i <= yearDays ? firstYear : lastDate.getFullYear();
      let day = (firstDayNumInYear + i) <= yearDays ? firstDayNumInYear + i : i - daysLeftInYear;
      days.push(formatToDtoDateString(dateFromDay(year, day)));
    }
  }
  return days;
}

export function getWeekDates(date: Date) {
  const weekBounds = getWeekBounds(date);
  return getDateRangeDates(weekBounds.from, weekBounds.to);
}

export function getWeekDayDateByNumber(date: Date, num: number): Date {
  return new Date(date.setDate(date.getDate() - date.getDay() + num));
}

export function getMenuTabsDate(date: Date) {
  const { i18n, t } = useTranslation();
  const dayStr: Object = t(`${LOCALE_PATH}.weekDays.${date.getDay()}`);

  let localizedDate = getLocalizedDate(date, i18n.language, { month: 'short', day: 'numeric' });
  const [ day, month, _ ] = localizedDate.split(' ');

  localizedDate = `${dayStr}, ${day} ${month}`;
  return localizedDate;
}

export function getTabDateByTabNum(tabNum: number, activeDate: Date) {
  const weekBounds = getWeekBounds(activeDate);
  const firstDate = weekBounds.from;
  return new Date(firstDate.setDate(firstDate.getDate() + tabNum - 1));
}

export const getTabDateValue = (tabNum: number, activeDate: Date) => {
  return getMenuTabsDate(getTabDateByTabNum(tabNum, activeDate));
};

export function toStringDateRange(dateRange: DateRangeProps): StringDateRangeProps {
  return {
    firstDate: formatToDtoDateString(dateRange.from),
    lastDate: formatToDtoDateString(dateRange.to),
  };
}