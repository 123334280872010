import { createActionsHook } from 'react-sweet-state';

import { createRestStore, Mapper } from '@intelliarts/ia-react-template';

import { Dish } from '../models/Dish';
import { DishView } from '../models/DishView';

export const DISHES_PATH = '/dishes';

const BASE_URL = '/api/';

const dishesMapper: Mapper<DishView, Dish> = {
  toModel: entity => {
    const {
      id,
      name,
      officialName,
      price,
      isEveryday,
      description,
      category,
      blockedCategories,
      isArchived,

    } = entity;

    return ({
      id,
      name,
      officialName,
      price,
      isEveryday,
      description,
      category,
      categoryName: category.name,
      blockedCategories,
      isArchived
    });
  },
  toEntity: model => {
    const {
      id,
      name,
      officialName,
      price,
      isEveryday,
      description,
      category,
      blockedCategories,
      isArchived
    } = model;

    const isArchivedDefault = isArchived ? isArchived : false;

    return {
      id,
      name,
      officialName,
      price,
      isEveryday,
      description,
      category,
      blockedCategories,
      isArchived: isArchivedDefault
    };
  }
};

export const DishesStore = createRestStore<DishView, Dish>({
  containerDisplayName: 'dishes-container',
  actionConfig: {
    resourcePath: DISHES_PATH,
    axiosConfig: { baseURL: BASE_URL },
  },
  mapper: dishesMapper,
});

export const useDishesActions = createActionsHook(DishesStore);
